import React from 'react'
import './footer.scss'
import getStrings from 'getStrings'


const UluFooter = (props) => {
    const strings = getStrings()

    return (
        <>
            <footer className="site-footer light-grey-bg">
                <div className="container">
                    <div className="footer-inner">
                        <div className="footer-wrap-mid">
                            <hr className="footerhr" />
                            <div className="footer-main">
                                <div className="footer-branding desktop-only">
                                    <img src="/images/ululab-logo.svg" alt="Ululab logo" width="194" height="77" />
                                </div>
                                <div className="footer-menus">
                                    <section id="nav_menu-2" className="widget widget_nav_menu">
                                        <h2 className="widget-title active spin-icon">{strings.FOOTER_MENU_COMPANY_H2}<span className="accord-arrow mobile-only"><img src="https://ululab.com/wp-content/uploads/2022/07/accord-arrow.svg" width="21" height="11.79" alt="" /></span></h2>
                                        <div className="menu-company-container">
                                            <ul id="menu-company" className="menu">
                                                <li className="menu-item"><a href="https://ululab.com/about/">{strings.FOOTER_MENU_COMPANY_LI_1}</a></li>
                                                <li className="menu-item"><a href="https://ululab.com/careers-2/">{strings.FOOTER_MENU_COMPANY_LI_2}</a></li>
                                                <li className="menu-item"><a href="https://ululab.com/contact-us/">{strings.FOOTER_MENU_COMPANY_LI_3}</a></li>
                                            </ul>
                                        </div>
                                    </section>
                                    <section id="nav_menu-3" className="widget widget_nav_menu">
                                        <h2 className="widget-title active spin-icon">{strings.FOOTER_MENU_OUR_APPS_H2}<span className="accord-arrow mobile-only"><img src="https://ululab.com/wp-content/uploads/2022/07/accord-arrow.svg" width="21" height="11.79" alt="" /></span></h2>
                                        <div className="menu-our-apps-container">
                                            <ul id="menu-our-apps" className="menu">
                                                <li className="menu-item"><a href="https://ululab.com/math-makers/">{strings.FOOTER_MENU_OUR_APPS_LI_1}</a></li>
                                                <li className="menu-item"><a href="https://ululab.com/our-educational-approach/">{strings.FOOTER_MENU_OUR_APPS_LI_2}</a></li>
                                                <li className="menu-item"><a href="https://ululab.com/educators/">{strings.FOOTER_MENU_OUR_APPS_LI_3}</a></li>
                                                <li className="menu-item"><a href="https://ululab.com/support/">{strings.FOOTER_MENU_OUR_APPS_LI_4}</a></li>
                                            </ul>
                                        </div>
                                    </section>
                                    <section id="nav_menu-4" className="widget widget_nav_menu">
                                        <h2 className="widget-title active spin-icon">{strings.FOOTER_MENU_PRESS_KITS_H2}<span className="accord-arrow mobile-only"><img src="https://ululab.com/wp-content/uploads/2022/07/accord-arrow.svg" width="21" height="11.79" alt="" /></span></h2>
                                        <div className="menu-press-kits-container">
                                            <ul id="menu-press-kits" className="menu">
                                                <li className="menu-item"><a href="https://ululab.com/press-kit-ululab/">{strings.FOOTER_MENU_PRESS_KITS_LI_1}</a></li>
                                                <li className="menu-item"><a href="https://ululab.com/press-kit-math-makers/">{strings.FOOTER_MENU_PRESS_KITS_LI_2}</a></li>
                                                <li className="menu-item"><a href="https://ululab.com/press-kit-slice-fractions/">{strings.FOOTER_MENU_PRESS_KITS_LI_3}</a></li>
                                                <li className="menu-item"><a href="https://ululab.com/press-kit-slice-fractions-2/">{strings.FOOTER_MENU_PRESS_KITS_LI_4}</a></li>
                                            </ul>
                                        </div>
                                    </section>
                                    <section id="nav_menu-5" className="widget widget_nav_menu">
                                        <h2 className="widget-title active spin-icon">{strings.FOOTER_MENU_LEGAL_H2}<span className="accord-arrow mobile-only"><img src="https://ululab.com/wp-content/uploads/2022/07/accord-arrow.svg" width="21" height="11.79" alt="" /></span></h2>
                                        <div className="menu-legal-container">
                                            <ul id="menu-legal" className="menu">
                                                <li className="menu-item"><a href="https://ululab.com/privacy-policy/">{strings.FOOTER_MENU_LEGAL_LI_1}</a></li>
                                                <li className="menu-item"><a href="https://ululab.com/terms-of-service/">{strings.FOOTER_MENU_LEGAL_LI_2}</a></li>
                                            </ul>
                                        </div>
                                    </section>
                                    <section className="last-menu">
                                        <h2 className="widget-title">{strings.FOOTER_MENU_SOCIAL_H2}<span className="accord-arrow mobile-only"><img src="https://ululab.com/wp-content/uploads/2022/07/accord-arrow.svg" width="21" height="11.79" alt="" /></span></h2>
                                        <ul>
                                            <li>
                                                <a href="https://twitter.com/ululab">
                                                    <div className="icon-social-wrapper">
                                                        <img src="https://ululab.com/wp-content/uploads/2022/08/twitter-icon.svg" alt="" />
                                                    </div>
                                                    <span className="social-title">Twitter</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.facebook.com/Ululab/">
                                                    <div className="icon-social-wrapper">
                                                        <img src="https://ululab.com/wp-content/uploads/2022/08/facebook-icon.svg" alt="" />
                                                    </div>
                                                    <span className="social-title">Facebook</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/mathmakersgame">
                                                    <div className="icon-social-wrapper">
                                                        <img src="https://ululab.com/wp-content/uploads/2022/08/instagram-icon.svg" alt="" />
                                                    </div>
                                                    <span className="social-title">Instagram</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </section>
                                </div>
                            </div>
                            <div className="low-wrap">
                                <hr className="hr-lower" />
                                <div className="copyright">
                                    <div className="copyr">
                                        <p>© 2023 Ululab, All rights reserved</p>
                                    </div>
                                    <div>
                                        <p>Made by <a href="https://glazedigital.com"><b>Glaze</b></a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default UluFooter
