import React from 'react'
import { withRouter } from 'react-router'
import axios from 'axios'
import getUrlParameters from '../../getUrlParameters'
import getStrings from '../../getStrings'
import ParentPortalDashboardDeleteAccount from './deleteaccount'
import getErrorMessage from '../../getErrorMessage'
import AppContext from 'core/appContext'
import styles from './manageaccount.module.scss'
import { getSubscriptionStatus, getUserData } from "../../Uluweb";
import getLanguage from "../../getLanguage";
import getCoupon from "../../getCoupon";

const PLAYFAB_TITLE_IDS = {
    prod: '8C087',
    dev: '634D9',
    danny: '634D9',
    gabriel: '634D9',
    francois: '634D9'
}

const PLAYFAB_EMAIL_TEMPLATES = {
    prod: 'A09520F6C9CB896C',
    dev: 'C3A7CC6A7FE9DE96',
    danny: 'C3A7CC6A7FE9DE96',
    gabriel: 'C3A7CC6A7FE9DE96',
    francois: 'C3A7CC6A7FE9DE96'
}


class ParentPortalManageAccount extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            deleteAccountUI: false,
            canSubscribe: false,
            userData: null
        }
    }
    PLAYFAB_METHODS = {
        SEND_ACCOUNT_RECOVERY_EMAIL: 'SendAccountRecoveryEmail'
    }

    componentDidMount() {
        this.verifyCanSubscribeOrRedirect()
    }

    verifyCanSubscribeOrRedirect = () => {
        if (!localStorage.getItem('session')){
            this.handleLogOut()
            return
        }
            
        const session = JSON.parse(localStorage.getItem('session'))
        if (!session.hasOwnProperty('token')) {
            this.handleLogOut()
            return
        }

        const promises = []
        let isSubscribed
        let userData

        promises.push(getSubscriptionStatus(session.token).then(result => {
            if (!result.success && result.error === "TOKEN_EXPIRED"){
                this.handleLogOut()
                return
            }

            if (result.success)
                isSubscribed = result.isSubscribed
        }))
        
        promises.push(getUserData(session.token).then(result => {
            if (!result.success && result.error === "TOKEN_EXPIRED"){
                this.handleLogOut()
                return
            }

            if (result.success)
                userData = result
        }))

        Promise.allSettled(promises).then(_ => {
            if(isSubscribed !== undefined && userData !== undefined) {
                this.setState({
                    userData: userData,
                    canSubscribe: !isSubscribed
                })
            }
        })
    }

    handleLogOut = () => {
        localStorage.removeItem('session')
        localStorage.removeItem('userInfo')
        this.props.history.push(`/?${getUrlParameters()}`)
        setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 0)
    }

    handlePasswordRecovery = async () => {
        const session = JSON.parse(localStorage.getItem('session'))

        const playfabAPIResponse = await this.trySendAccountRecoveryEmail(session.email, session.data.SessionTicket)
        if (playfabAPIResponse.status === 200) {
            const strings = getStrings()
            alert(strings.PARENT_PORTAL_ALERT_RESET_PASSWORD_SUCCESS)
        } else {
            this.displayError(playfabAPIResponse.data.error)
        }
    }

    handleDeleteAccount = async () => this.setState({deleteAccountUI: true})

    trySendAccountRecoveryEmail = async (email, sessionTicket) => {
        try {
            return await this.sendAccountRecoveryEmail(email, sessionTicket)
        } catch (err) {
            this.blockUI(false)
            this.displayError(err.response.data.error)
        }
    }

    sendAccountRecoveryEmail = async (email, sessionTicket) => {
        return await axios.post(
            this.getPlayfabClientAPIEndpoint(this.PLAYFAB_METHODS.SEND_ACCOUNT_RECOVERY_EMAIL),
            this.getSendAccountRecoveryEmailPayload(email),
            this.jsonFormHeader(sessionTicket)
        )
    }

    jsonFormHeader(sessionTicket) {
        const headers = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        if (sessionTicket)
            headers['headers']['X-Authorization'] = sessionTicket

        return headers
    }

    blockUI = (shouldBlock) => this.context.setBlockUI(shouldBlock)

    getSendAccountRecoveryEmailPayload = (email) => {
        return {
            TitleId: PLAYFAB_TITLE_IDS[process.env['REACT_APP_ENV']],
            Email: email,
            EmailTemplateId: PLAYFAB_EMAIL_TEMPLATES[process.env['REACT_APP_ENV']]
        }
    }

    displayError = (errorCode) => {
        const strings = getStrings()
        if (!errorCode || Object.keys(errorCode).length === 0)
            return alert(strings.PARENT_PORTAL_MANAGE_ACCOUNT_ERROR_GENERIC_ERROR)
        
        alert(getErrorMessage('PARENT_PORTAL_MANAGE_ACCOUNT_ERROR_', errorCode, strings))
    }

    getPlayfabClientAPIEndpoint = (method = '') => {
        return `https://${PLAYFAB_TITLE_IDS[process.env['REACT_APP_ENV']]}.playfabapi.com/Client/${method}`
    }

    deleteAccountDisplay(shouldDisplay) {
        this.setState({deleteAccountUI: shouldDisplay})
    }

    getStripeCustomerID = () => {
        if (this.state.userData) {
            const integration = this.state.userData.integrations.find(integration => integration.service === 'Stripe')
            if (integration)
                return integration.serviceKey
        }

        return ''
    }

    render() {
        const sessionString = localStorage.getItem('session')
        if (!sessionString)
            return <></>

        const session = JSON.parse(sessionString)
        const email = session.email
        const stripeCustomerID = this.getStripeCustomerID()

        const strings = getStrings()

        return (
            <>
                <ParentPortalDashboardDeleteAccount 
                    shown={this.state.deleteAccountUI}
                    deleteAccountDisplay={this.deleteAccountDisplay.bind(this)}
                    userData={this.state.userData} />
                <div className='form-outer form-container'>
                    <div className='form-box'>
                        <h2 className='text-center'>{strings.PARENT_PORTAL_MANAGE_ACCOUNT_H2}</h2>

                        <p className='text-center'>{strings.PARENT_PORTAL_MANAGE_ACCOUNT_EMAIL_LABEL}: {email}</p>

                        <div className='flex-row justify-center gap-2'>
                            {
                                stripeCustomerID &&
                                    <form method="POST" action={`${process.env['REACT_APP_STRIPE_API_ENDPOINT']}/subscription/manage`}>
                                        <input
                                            type='text'
                                            name='customerID'
                                            style={{display: 'none'}}
                                            defaultValue={stripeCustomerID}
                                        />
                                        <button className='button large contained primary' type="submit">{strings.PARENT_PORTAL_MANAGE_ACCOUNT_MANAGE_SUBSCRIPTION_BUTTON}</button>
                                    </form>
                            }
                            {
                                !stripeCustomerID && this.state.canSubscribe &&
                                    <div>
                                        <button 
                                            className='button large contained primary'
                                            onClick={async () => {
                                                this.blockUI(true)
                                                const stripeResponse = await axios.post(`${process.env['REACT_APP_STRIPE_API_ENDPOINT']}/subscription/purchaseSession`, {
                                                    userID: this.state.userData.id,
                                                    lang: getLanguage(),
                                                    coupon: getCoupon()
                                                })
                                                window.location.replace(stripeResponse.data.session.url)
                                            }}
                                        >
                                            {strings.PARENT_PORTAL_MANAGE_ACCOUNT_SUBSCRIBE_BUTTON}
                                        </button>
                                    </div>
                                        
                            }
                        </div>

                        <div className='flex-row justify-center gap-2 margin-top-2 margin-bottom-2'>
                            <button 
                                className='button bordered blue'
                                onClick={this.handlePasswordRecovery.bind(this)}
                            >
                                {strings.PARENT_PORTAL_MANAGE_ACCOUNT_RESET_PASSWORD_BUTTON}
                            </button>
                            <button className='button bordered blue' onClick={this.handleLogOut.bind(this)}>
                                {strings.PARENT_PORTAL_MANAGE_ACCOUNT_LOG_OUT_BUTTON}
                            </button>
                            <button 
                                className='button contained warn'
                                onClick={this.handleDeleteAccount.bind(this)}
                            >
                                {strings.PARENT_PORTAL_MANAGE_ACCOUNT_DELETE_ACCOUNT_BUTTON}
                            </button>
                        </div>

                        <img src={`${process.env.PUBLIC_URL}/images/parentportal/IpadMockups.png`} alt='iPad mockups' className={styles['ipad-images']} />
                    </div>
                </div>
            </>
        )
    }
}

ParentPortalManageAccount.contextType = AppContext

export default withRouter(ParentPortalManageAccount)