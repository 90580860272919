import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'

import ParentPortalHeader from '../header'
import ParentPortalSignInForm from './form'
import getUrlParameters from 'getUrlParameters'
import {logAnalytics, EVENT_TYPES} from 'logAnalytics'
import DidYouKnowCTA from 'core/didyouknow'


function ParentPortalSignIn(props) {
    const [mounted, setMounted] = useState(false)
    const [isSignedInOnPageOpen] = useState(localStorage.getItem('session'))

    useEffect(() => {
        document.title = 'Math Makers Parent Portal Sign In'

        logAnalytics(EVENT_TYPES.PAGE_VIEW, {page: document.title})

        setMounted(true)
    }, [])

    if (!mounted)
        return <></>

    if (isSignedInOnPageOpen)
        props.history.push(`/dashboard?${getUrlParameters()}`)

    return (
        <>
            <div className='hero-bg'>
                <ParentPortalHeader />
                <div className='form-outer'>
                    <div className='form-container'>
                        <ParentPortalSignInForm />
                    </div>
                </div>
            </div>
            <DidYouKnowCTA />
        </>
    )
}

export default withRouter(ParentPortalSignIn)