import getStrings from "getStrings"
import React from "react"


const KidSafe = (props) => {
  const strings = getStrings()

  return (
    <div className='kidsafe-container'>
        <img src="https://www.kidsafeseal.com/sealimage/12754033531639855815/mathmakers_app_large_darktm.png" alt="KidSafe Logo" />
        <div className='text-left'>
            <p className='small-text'><strong>{strings.KIDSAFE_PARAGRAPH_1}</strong></p>
            <p className='tiny-text'>{strings.KIDSAFE_PARAGRAPH_2}{' '}
                <a href='https://kidsafeseal.com'>kidsafeseal.com</a>
            </p>
        </div>
    </div>
  )
}

export default KidSafe
