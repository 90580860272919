import React from 'react'
import LogosContainer from 'LogosContainer'
import getStrings from 'getStrings'
import styles from './benefits.module.scss'


const ParentPortalIntroBenefits = (props) => {
  const strings = getStrings()

  return (
    <>
      <div className='parentportal-benefits'>
        <div className='container flex-row'>
          <img src="/images/parentportal/intro-mm-ipad.png" alt="iPad mockup" className={styles.introImage} />
          <div>
            <h1 className='text-orange'>{strings.PARENT_PORTAL_BENEFITS_HEADER}</h1>
            <p>{strings.PARENT_PORTAL_BENEFITS_PARAGRAPH}</p>
          </div>
        </div>
      </div>
      <div className='parentportal-membership'>
        <div className='container flex-row'>
          <img src="/images/parentportal/intro-characters.png" alt="Math Makers characters" className={styles.introImage} />
          <div>
            <h1 className='text-blue'>{strings.TEACHER_LOUNGE_BENEFITS_HEADER}</h1>
            <ul className='margin-left-1'>
              <li><img src="/images/orange-check.png" className='icon' alt="" />{strings.TEACHER_LOUNGE_BENEFITS_1}</li>
              <li><img src="/images/orange-check.png" className='icon' alt="" />{strings.TEACHER_LOUNGE_BENEFITS_2}</li>
              <li><img src="/images/orange-check.png" className='icon' alt="" />{strings.TEACHER_LOUNGE_BENEFITS_3}</li>
              <li><img src="/images/orange-check.png" className='icon' alt="" />{strings.TEACHER_LOUNGE_BENEFITS_4}</li>
            </ul>
            <LogosContainer />
          </div>
        </div>
      </div>
    </>
  )
}

export default ParentPortalIntroBenefits