import axios from 'axios'

export const getSubscriptionStatus = async (token) => {
    const queryParams = Buffer.from(JSON.stringify({token: token})).toString('base64')
    const url = `${process.env['REACT_APP_USER_API']}/subscription/status?p=${queryParams}`

    try {
        const result = await axios.get(url)
        return result.data
    } catch (err) {
        console.error(url, err)
        return {success: false}
    }
}

export const getUserData = async (token) => {
    const queryParams = Buffer.from(JSON.stringify({token: token})).toString('base64')
    const url = `${process.env['REACT_APP_USER_API']}?p=${queryParams}`

    try {
        const result = await axios.get(url)
        return result.data
    } catch (err) {
        console.error(url, err)
        return {success: false}
    }
}

/*

*/