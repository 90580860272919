import React from 'react'
import { withRouter } from 'react-router'

import ParentPortalHeader from '../header'
import ParentPortalSignUpForm from './form'
import ParentPortalIntroSalesPitch from './salespitch'
import getStrings from 'getStrings'
import getUrlParameters from 'getUrlParameters'
import {logAnalytics, EVENT_TYPES} from 'logAnalytics'
import DidYouKnowCTA from 'core/didyouknow'

class ParentPortalSignUp extends React.Component {
    constructor(props) {
        super(props)
        
        this.state = {
            mounted: false,
        }
    }

    componentDidMount() {        
        document.title = 'Math Makers Parent Portal Sign Up'

        logAnalytics(EVENT_TYPES.PAGE_VIEW, {page: document.title})

        this.setState({
            mounted: true
        })
    }

    render() {
        if (!this.state.mounted)
            return "";

        const strings = getStrings()

        const isSignedIn = localStorage.getItem('session')
        if (isSignedIn)
            this.props.history.push(`/dashboard?${getUrlParameters()}`)

        return (
            <>
                <div className='hero-bg'>
                    <ParentPortalHeader />
                    <div className='form-outer'>
                        <div className="flex-row container gap-2">
                            <ParentPortalIntroSalesPitch />
                            <div className='form-container half-width'>
                                <h1 className='text-center text-blue'>{strings.PARENT_PORTAL_SIGN_UP_H1}</h1>
                                <p className='text-center'>{strings.PARENT_PORTAL_SIGN_UP_P1}</p>
                                <ParentPortalSignUpForm />
                            </div>
                        </div>
                    </div>
                </div>
                <DidYouKnowCTA />
            </>
        )
    }
}

export default withRouter(ParentPortalSignUp)