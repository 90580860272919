import React from "react"
import getStrings from "getStrings"
import styles from './didyouknow.module.scss'


const DidYouKnowCTA = (props) => {
  const strings = getStrings()

  return (
    <section className={styles.banner}>
      <div className={styles.bannerInner + ' container'}>
        <span className={styles.span}>{strings.DID_YOU_KNOW_SPAN}</span>
        <h1 className={styles.headline}>{strings.DID_YOU_KNOW_H4}</h1>
        <div className={styles.buttonwrap + ' justify-center'}>
          <a className="button bordered white" href="https://ululab.com/#customers-say">{strings.DID_YOU_KNOW_A_1}</a>
          <a className="button bordered black" href="https://ululab.com/math-makers">{strings.DID_YOU_KNOW_A_2}</a>
        </div>
      </div>
    </section>
  )
}
export default DidYouKnowCTA
