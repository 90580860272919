import React, { useEffect } from 'react'
import { withRouter } from 'react-router'

import ParentPortalHeader from '../header'
import ParentPortalIntroBenefits from './benefits'
import {logAnalytics, EVENT_TYPES} from 'logAnalytics'
import getStrings from 'getStrings'
import KidSafe from 'core/kidsafe'
import DidYouKnowCTA from 'core/didyouknow'
import getUrlParameters from 'getUrlParameters';


const ParentPortalIntro = (props) => {
    useEffect(() => {
        document.title = 'Math Makers Parent Portal'

        logAnalytics(EVENT_TYPES.PAGE_VIEW, {page: document.title})
    })

    const isSignedIn = localStorage.getItem('session')
    if (isSignedIn)
        props.history.push('/dashboard')
    
    const strings = getStrings()

    return (
        <>
            <div className='hero-bg'>
                <ParentPortalHeader />
                <div className='container text-center schoolzone--hero'>
                    <p className='lede'>{strings.PARENT_PORTAL_INTRO_LEDE}</p>
                    <h1 className='text-blue'>{strings.PARENT_PORTAL_INTRO_TOP_BANNER}</h1>
                    <KidSafe />
                    <a href={`/signup/?${getUrlParameters()}`} className='hero-cta button contained primary margin-top-4 margin-bottom-2 margin-left-auto margin-right-auto'>{strings.PARENT_PORTAL_HEADER_START_TRIAL}</a>
                </div>
            </div>
            <ParentPortalIntroBenefits />
            <DidYouKnowCTA />
        </>
    )
}

export default withRouter(ParentPortalIntro)