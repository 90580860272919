import React from 'react'
import LogosContainer from 'LogosContainer'
import getStrings from 'getStrings'


const ParentPortalIntroSalesPitch = (props) => {
  const strings = getStrings()

  return (
    <>
      <div className='form-container half-width'>
        <img src="/images/parentportal/MathMakers_Title_Banner_03-3.png" alt="Math Makers" className="full-width margin-bottom-5" />
        <ul className='margin-left-1'>
          <li><img src="/images/orange-check.png" className='icon' alt="" />{strings.PARENT_PORTAL_SIGN_UP_BENEFITS_1}</li>
          <li><img src="/images/orange-check.png" className='icon' alt="" />{strings.PARENT_PORTAL_SIGN_UP_BENEFITS_2}</li>
          <li><img src="/images/orange-check.png" className='icon' alt="" />{strings.PARENT_PORTAL_SIGN_UP_BENEFITS_3}</li>
          <li><img src="/images/orange-check.png" className='icon' alt="" />{strings.PARENT_PORTAL_SIGN_UP_BENEFITS_4}</li>
          <li><img src="/images/orange-check.png" className='icon' alt="" />{strings.PARENT_PORTAL_SIGN_UP_BENEFITS_5}</li>
          <li><img src="/images/orange-check.png" className='icon' alt="" />{strings.PARENT_PORTAL_SIGN_UP_BENEFITS_6}</li>
        </ul>
        <LogosContainer />
      </div>
    </>
  )
}

export default ParentPortalIntroSalesPitch