import React from 'react'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { withRouter } from 'react-router'
import getStrings from 'getStrings'
import getUrlParameters from 'getUrlParameters'
import ReactGA from "react-ga4"
import getErrorMessage from 'getErrorMessage'
import AppContext from 'core/appContext'
import { Cookies, defaultCookieConsentName, getCookieConsentValue, resetCookieConsentValue, SAME_SITE_OPTIONS } from 'react-cookie-consent'

const PLAYFAB_METHODS = {
    REGISTER_PLAYFAB_USER: 'RegisterPlayFabUser',
    LOGIN_WITH_EMAIL_ADDRESS: 'LoginWithEmailAddress',
    LINK_CUSTOM_ID: 'LinkCustomID',
    LINK_IOS_DEVICE_ID: 'LinkIOSDeviceID',
    LINK_ANDROID_DEVICE_ID: 'LinkAndroidDeviceID'
}

const PLAYFAB_EMAIL_TEMPLATES = {
    prod: 'A09520F6C9CB896C',
    dev: 'C3A7CC6A7FE9DE96',
    danny: 'C3A7CC6A7FE9DE96',
    gabriel: 'C3A7CC6A7FE9DE96',
    francois: 'C3A7CC6A7FE9DE96'
}

const DASHBOARD_PAGE_PATH = '/dashboard'

const PLAYFAB_TITLE_IDS = {
    prod: '8C087',
    dev: '634D9',
    danny: '634D9',
    gabriel: '634D9',
    francois: '634D9'
}

const FORMS = {
    SIGNIN: 'signin',
    FORGOT_PASSWORD: 'forgotPassword'
}
  

class ParentPortalSignInForm extends React.Component {
    PLAYFAB_METHODS = {
        SEND_ACCOUNT_RECOVERY_EMAIL: 'SendAccountRecoveryEmail'
    }

    constructor(props) {
        super(props)

        this.state = {
            form: FORMS.SIGNIN,
            cookieConsent: getCookieConsentValue(),
        }
    }

    signInClicked = async (event) => {
        event.preventDefault()

        if (!document.forms[event.target.dataset.form].reportValidity()) return        // EXIT

        const callerFormName = event.target.dataset.form
        const formData = this.getFormDataJSON(callerFormName)

        if (!formData.Email || !formData.Password || !getCookieConsentValue()) { // It should only get on browsers that don't support .reportValidity() (Mobile Firefox)
            alert('All fields are required')
            return  // EXIT
        }
        
        this.blockUI(true)

        const playfabAPIResponse = await this.tryLoginWithEmailAddress(callerFormName)
        if (!playfabAPIResponse) // Playfab returned an error and has already shown the error message to the client
            return  // EXIT

        if (playfabAPIResponse.status !== 200) {
            // this is the only error returned by PlayFab on this call: https://docs.microsoft.com/en-us/rest/api/playfab/client/authentication/login-with-email-address?view=playfab-rest
            this.displayError('InvalidEmailOrPassword')
        } else {
            const token = uuidv4()

            try {
                const response = await this.storeToken(token, playfabAPIResponse.data.data.PlayFabId)
                localStorage.setItem('userInfo', JSON.stringify(response.data.userAPIResponse))
            } catch (err) {
                this.displayError('GENERIC_ERROR')
                this.blockUI(false)
                return
            }

            const sessionObject = {
                ...playfabAPIResponse.data,
                token: token,
                email: formData.Email
            }

            localStorage.setItem('session', JSON.stringify(sessionObject))
            ReactGA.event('sign_in', sessionObject)

            await this.updateIntegrations(token)

            this.props.history.push(`${DASHBOARD_PAGE_PATH}?${getUrlParameters()}`)
        }
        
        this.blockUI(false)
    }

    tryLoginWithEmailAddress = async (callerFormName) => {
        try {
            return await axios.post(
                this.getPlayfabClientAPIEndpoint(PLAYFAB_METHODS.LOGIN_WITH_EMAIL_ADDRESS),
                this.getLoginWithEmailPayload(callerFormName),
                this.jsonFormHeader()
            )
        } catch (err) {
            this.blockUI(false)
            this.displayError(err.response.data.error)
        }
    }

    getFormDataJSON = (formName) => Object.fromEntries(new FormData(document.forms[formName]).entries())

    wwwFormHeader() {
        return {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
    }

    jsonFormHeader(sessionTicket) {
        const headers = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        if (sessionTicket)
            headers['headers']['X-Authorization'] = sessionTicket

        return headers
    }

    async storeToken(token, playfabID) {
        // TODO refactor needed. this calls Uluweb's Playfab API while signup/form.js calls Uluweb's User API for the same purpose.
        return await axios.post(
            process.env['REACT_APP_PLAYFAB_API'],
            {
                FunctionArgument: {
                    playfabID: playfabID,
                    args: {
                        token: token,
                        userApiVersion: process.env['REACT_APP_PLAYFAB_API_VERSION']
                    }
                }
            },
            this.jsonFormHeader()
        )
    }

    async updateIntegrations(token) {
        // TODO refactor needed. this is done differently than in signup/form.js for no good reasons.
        return await axios.put(
            `${process.env['REACT_APP_USER_API']}/integrations${this.props.location.search ? this.props.location.search + '&p=1' : '?p=1'}`,
            this.getUpdateIntegrationsPayload(token),
            this.wwwFormHeader()
        )
    }

    getUpdateIntegrationsPayload = (token = '') => {
        const queryParams = new URLSearchParams()

        queryParams.append('token', token)

        return queryParams
    }

    async forgottenPasswordClicked() {
        const formData = this.getFormDataJSON('formForgottenPassword')
        const playfabAPIResponse = await this.trySendAccountRecoveryEmail(formData.Email)
        if (playfabAPIResponse && playfabAPIResponse.status === 200) {
            const strings = getStrings()
            alert(strings.PARENT_PORTAL_ALERT_RESET_PASSWORD_SUCCESS)
            this.setState({form: FORMS.SIGNIN})
        } else {
            this.displayError(playfabAPIResponse.data.error)
        }
    }

    trySendAccountRecoveryEmail = async (email) => {
        try {
            return await this.sendAccountRecoveryEmail(email)
        } catch (err) {
            this.blockUI(false)
            this.displayError(err.response.data.error)
        }
    }

    sendAccountRecoveryEmail = async (email) => {
        return await axios.post(
            this.getPlayfabClientAPIEndpoint(this.PLAYFAB_METHODS.SEND_ACCOUNT_RECOVERY_EMAIL),
            this.getSendAccountRecoveryEmailPayload(email),
            this.jsonFormHeader()
        )
    }

    getSendAccountRecoveryEmailPayload = (email) => {
        return {
            TitleId: PLAYFAB_TITLE_IDS[process.env['REACT_APP_ENV']],
            Email: email,
            EmailTemplateId: PLAYFAB_EMAIL_TEMPLATES[process.env['REACT_APP_ENV']]
        }
    }

    blockUI = (shouldBlock) => this.context.setBlockUI(shouldBlock)

    getLoginWithEmailPayload = (formName) => {
        const payload = this.getFormDataJSON(formName)
        payload.TitleId = PLAYFAB_TITLE_IDS[process.env['REACT_APP_ENV']]
        payload.RequireBothUsernameAndEmail = false

        return payload
    }

    displayError = (errorCode) => {
        const strings = getStrings()
        if (!errorCode || Object.keys(errorCode).length === 0)
            return alert(strings.PARENT_PORTAL_MANAGE_ACCOUNT_ERROR_GENERIC_ERROR)
        
        alert(getErrorMessage('PARENT_PORTAL_SIGN_IN_FORM_ERROR_', errorCode, strings))
    }

    getPlayfabClientAPIEndpoint = (method = '') => {
        return `https://${PLAYFAB_TITLE_IDS[process.env['REACT_APP_ENV']]}.playfabapi.com/Client/${method}`
    }

    setCookieConsent = (value) => {
        if (!value) {
            resetCookieConsentValue()
        }
        else {
            const cookieSecurity = window.location ? window.location.protocol === 'https:' : true
            Cookies.set(defaultCookieConsentName, 'true', { expires: 365, sameSite: SAME_SITE_OPTIONS.LAX, secure: cookieSecurity })
        }
    }

    render() {
        return (
            <>
                {this.state.form === FORMS.SIGNIN && this.renderSignInForm()}
                {this.state.form === FORMS.FORGOT_PASSWORD && this.renderForgottenPasswordForm()}
            </>
        )
    }

    renderSignInForm() {
        const strings = getStrings()

        return (
            <>
                <h1 className='text-center text-blue margin-bottom-5'>{strings.PARENT_PORTAL_SIGN_IN_FORM_H2}</h1>
                <div className='form-box'>
                    <p><strong>{strings.PARENT_PORTAL_SIGN_IN_FORM_CREATE_ACCOUNT_P}{' '}
                        <a href={`/signup?${getUrlParameters()}`}>{strings.PARENT_PORTAL_SIGN_IN_FORM_CREATE_ACCOUNT_LINK}</a></strong>
                    </p>
                    <form id='formSignIn'>
                        <input name='Email' type='email' placeholder={strings.PARENT_PORTAL_FORM_EMAIL} required className='margin-bottom-2' />
                        <input name='Password' type='password' placeholder={strings.PARENT_PORTAL_SIGN_IN_FORM_PASSWORD} required className='margin-bottom-2' />
                        { !this.state.cookieConsent && (
                            <label className='flex-row small-text margin-bottom-2'>
                                <input name="Cookies" type='checkbox' required className='margin-right-2' onChange={(event) => this.setCookieConsent(event.target.checked)} />
                                <span>{strings.PARENT_PORTAL_SIGN_UP_FORM_COOKIE_CONSENT_P}</span>
                            </label>
                        ) }
                        <p className='margin-bottom-2 text-right'>
                            <button className='link text-blue' type='button' onClick={() => this.setState({form: FORMS.FORGOT_PASSWORD})}>{strings.PARENT_PORTAL_SIGN_IN_FORM_FORGOT_PASSWORD_LINK}</button> 
                        </p>
                        <button 
                            data-form='formSignIn'
                            onClick={this.signInClicked.bind(this)}
                            className="form-button"
                            type='submit'
                        >
                            {strings.PARENT_PORTAL_SIGN_IN_FORM_BUTTON}
                        </button>
                    </form>
                </div>
            </>
        )
    }

    renderForgottenPasswordForm() {
        const strings = getStrings()

        return (
            <>
                <h1 className='text-center text-blue'>{strings.PARENT_PORTAL_FORGOTTEN_PASSWORD_FORM_H2}</h1>
                <p className='text-center'>{strings.PARENT_PORTAL_FORGOTTEN_PASSWORD_FORM_P}</p>
                <div className='form-box'>
                    <p><strong>{strings.PARENT_PORTAL_FORGOTTEN_PASSWORD_FORM_P_2}</strong></p>
                    <form id='formForgottenPassword'>
                        <input id='emailForgottenPasswordForm' name='Email' type='email' placeholder={strings.PARENT_PORTAL_FORM_EMAIL} required className='margin-bottom-2' />
                        <button
                            data-form='formForgottenPassword'
                            onClick={this.forgottenPasswordClicked.bind(this)}
                            className='form-button'
                        >
                            {strings.PARENT_PORTAL_FORGOTTEN_PASSWORD_FORM_BUTTON}
                        </button>
                    </form>
                </div>
            </>
        )
    }
}

ParentPortalSignInForm.contextType = AppContext

export default withRouter(ParentPortalSignInForm)