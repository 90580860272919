import React, { useEffect } from 'react'
import qs from 'qs'
import { withRouter } from 'react-router-dom'

import ParentPortalHeader from '../header'
import getStrings from 'getStrings'
import styles from './confirmation.module.scss'
import DidYouKnowCTA from 'core/didyouknow'

const ParentPortalConfirmation = (props) => {
    useEffect(() => {
        document.title = 'Math Makers Parent Portal Download'
    }, [])

    const { playfab_token } = qs.parse(props.location.search, { ignoreQueryPrefix: true })
    const oneLinkQueryParams = playfab_token ? `?playfab_token=${playfab_token}` : ''
    const strings = getStrings()

    const specialStyles = {
        fontFamily: "Helvetica",
    };

    return (
        <>
            <div className='hero-bg'>
                <ParentPortalHeader />
                <div className={styles.confirmWrap + ' flex-row gap-2 container form-outer'}>
                    <div className={styles.nextSteps + ' text-center'}>
                        <h2 className='text-blue' style={specialStyles}>{strings.PARENT_PORTAL_CONFIRMATION_H2}:</h2>
                        <h3 className='text-blue' style={specialStyles}>1. {strings.PARENT_PORTAL_CONFIRMATION_H3_1}</h3>
                        <div className='flex-row justify-center margin-bottom-2'>
                            <a href={`${process.env['REACT_APP_ONELINK_IOS']}/${oneLinkQueryParams}`}>
                                <img className='pointer' src={`${process.env.PUBLIC_URL}/images/parentportal/AppStore.png`} alt='Apple App Store' height='64px' width='211px' />
                            </a>
                            <a href={`${process.env['REACT_APP_ONELINK_ANDROID']}/${oneLinkQueryParams}`}>
                                <img className='pointer' src={`${process.env.PUBLIC_URL}/images/parentportal/GooglePlay.png`} alt='Apple App Store' height='64px' width='211px' />
                            </a>
                        </div>
                        <h3 className='text-blue' style={specialStyles}>2. {strings.PARENT_PORTAL_CONFIRMATION_H3_2}</h3>
                        <p>{strings.PARENT_PORTAL_CONFIRMATION_P_2}</p>
                        <img className='confirmation-in-app-login-img' src={`${process.env.PUBLIC_URL}/images/parentportal/InAppLogin.png`} alt='' />
                        <br/>
                        <h3 className='text-blue' style={specialStyles}>3. {strings.PARENT_PORTAL_CONFIRMATION_H3_3}</h3>
                        <p>{strings.PARENT_PORTAL_CONFIRMATION_P_3}</p>
                    </div>
                    <div>
                        <h1 className='text-center text-blue'>{strings.PARENT_PORTAL_CONFIRMATION_H1}</h1>
                        <a className='button contained primary margin-left-auto margin-right-auto margin-bottom-2' href='/signin'>{strings.PARENT_PORTAL_CONFIRMATION_MANAGE_SUBSCRIPTION_BUTTON}</a>
                        <img className='confirmation-splash-img' src={`${process.env.PUBLIC_URL}/images/parentportal/IpadMockups.png`} alt='' />
                    </div>
                </div>
            </div>
            <DidYouKnowCTA />
        </>
    )
}

export default withRouter(ParentPortalConfirmation)