import axios from 'axios'
import React from 'react'

import './devtools.css'

const ADMIN_BASE_ENDPOINTS = {
    prod: 'https://bjd9ywxpt9.execute-api.us-east-1.amazonaws.com/prod',
    dev: 'https://eelrginfqj.execute-api.us-east-1.amazonaws.com/dev',
    danny: 'https://k25vmd4uub.execute-api.us-east-1.amazonaws.com/danny',
    gabriel: 'https://k25vmd4uub.execute-api.us-east-1.amazonaws.com/danny',
    francois: 'https://k25vmd4uub.execute-api.us-east-1.amazonaws.com/danny'
}

class DevToolsUGC extends React.Component {
    constructor(props) {
        super(props)

        this.state = {urls: []}
    }

    componentDidMount() {
        document.title = 'Dev Tools - UGC'
    }

    handleFileChange = (event) => {
        const file = event.target.files[0];
        
        // setting up the reader
        const reader = new FileReader();
        reader.readAsText(file,'UTF-8');

        // here we tell the reader what to do when it's done reading...
        reader.onload = async (readerEvent) => {
            const content = readerEvent.target.result; // this is the content!
            const arr = this.csvToArray(content);
            const levelNames = arr.map(obj => obj['"LevelName"'].replaceAll('"', '').replace('~', '__'))
            const response = await axios.get(`${ADMIN_BASE_ENDPOINTS[process.env['REACT_APP_ENV']]}/admin/download/ugc/levels`, {levelNames: levelNames})
            
            if (response.data.success)
                this.setState({urls: response.data.urls})
            else
                alert(response.data.error)
        }
    }

    csvToArray = (str, delimiter = ",") => {
        // slice from start of text to the first \n index
        // use split to create an array from string by delimiter
        const headers = str.slice(0, str.indexOf("\n")).split(delimiter);
      
        // slice from \n index + 1 to the end of the text
        // use split to create an array of each csv value row
        const rows = str.slice(str.indexOf("\n") + 1).split("\n");
      
        // Map the rows
        // split values from each row into an array
        // use headers.reduce to create an object
        // object properties derived from headers:values
        // the object passed as an element of the array
        const arr = rows.map(function (row) {
          const values = row.split(delimiter);
          const el = headers.reduce(function (object, header, index) {
            object[header] = values[index];
            return object;
          }, {});
          return el;
        });
      
        // return the array
        return arr;
      }

    render() {
        return (
            <div>
                <h1>UGC Dev Tools</h1>
                <hr />
                <h2>Download levels from level names csv file</h2>
                <input id='csvfile' type='file' onChange={this.handleFileChange.bind(this)} /><br />

                {this.state.urls.length > 0 &&
                    this.state.urls.map((url, index) => {
                        const parts = url.split('/')
                        return <div className='level-box' key={index}>
                            <input type={'checkbox'} style={{position: 'relative', top: '5px', left: '5px'}} checked />
                            <br /><br />
                            <img src={`${process.env.PUBLIC_URL}/images/devtools/level_preview.jpg`} alt='preview' width={'640px'} />
                            <br />
                            <a href={url} style={{color: 'blue'}} target='_blank' rel='noreferrer'>{parts[parts.length-1].split('?')[0]}</a>
                        </div>
                    })
                }

                {this.state.urls.length > 0 &&
                    // Not yet implemented. Implement when level previews are available
                    <div style={{padding: '30px'}}><button>Download Selected</button></div>
                }
            </div>
        )
    }
}

export default DevToolsUGC