import strings from './strings.json'
import getLanguage from './getLanguage'

const DEFAULT_LANGUAGE = 'en'

export default function getStrings() {
    let language = getLanguage()
    if (!strings.hasOwnProperty(language))
        language = DEFAULT_LANGUAGE

    return strings[language]
}