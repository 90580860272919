import axios from 'axios'

export const EVENT_TYPES = {
    'PAGE_VIEW': 'PageView'
}

export const logAnalytics = (eventType, data) => 
    axios.post(
        `${process.env['REACT_APP_ANALYTICS_API']}/log`, 
        {
            type: eventType,
            timestamp: Date.now(),
            data: JSON.stringify(data)
        }
    ).then(() => {})