import React from 'react'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { withRouter } from 'react-router'
import { Cookies, defaultCookieConsentName, getCookieConsentValue, resetCookieConsentValue, SAME_SITE_OPTIONS } from 'react-cookie-consent'
import ReactGA from "react-ga4"

import getStrings from 'getStrings'
import getLanguage from 'getLanguage'
import getCoupon from 'getCoupon'
import getUrlParameters from 'getUrlParameters'
import AppContext from 'core/appContext'


const PLAYFAB_METHODS = {
    REGISTER_PLAYFAB_USER: 'RegisterPlayFabUser',
    LINK_CUSTOM_ID: 'LinkCustomID',
    LINK_IOS_DEVICE_ID: 'LinkIOSDeviceID',
    LINK_ANDROID_DEVICE_ID: 'LinkAndroidDeviceID'
}

const ERRORS = { // This contains errors coming both from Uluweb and Playfab, hence the different format.
    en: {
        GENERIC_ERROR: 'Our servers encountered an error.',
        INVALID_PAYLOAD: 'Please verify that your email and password are valid.',
        USER_ALREADY_EXISTS: 'This user already exists.',
        EmailAddressNotAvailable: 'This email address is not available. Have you already created an account?',
        InvalidEmailAddress: 'This email address is not valid',
        InvalidEmailOrPassword: 'This email address or password is not valid',
        InvalidPassword: 'This password is not valid'
    },
    fr: {
        GENERIC_ERROR: 'Nos serveurs ont rencontré une erreur.',
        INVALID_PAYLOAD: 'Veuillez vérifier que votre courriel et votre mot de passe sont valides.',
        USER_ALREADY_EXISTS: 'Cet utilisateur existe déjà.',
        EmailAddressNotAvailable: "Cette adresse courriel n'est pas disponible. Avez-vous déjà créé un compte?",
        InvalidEmailAddress: "Cette adresse courriel n'est pas valide",
        InvalidEmailOrPassword: "Cette adresse courriel ou ce mot de passe n'est pas valide",
        InvalidPassword: "Ce mot de passe n'est pas valide"
    }
}

const PLAYFAB_TITLE_IDS = {
    prod: '8C087',
    dev: '634D9',
    danny: '634D9',
    gabriel: '634D9',
    francois: '634D9'
}

const USER_BASE_ENDPOINTS = {
    prod: 'https://oss5z18d5j.execute-api.us-east-1.amazonaws.com/prod',
    dev: 'https://7wcvlljf6a.execute-api.us-east-1.amazonaws.com/dev',
    danny: 'https://3acpem99q9.execute-api.us-east-1.amazonaws.com/danny',
    gabriel: 'https://5feoxzthgi.execute-api.us-east-1.amazonaws.com/gabriel'
}
  

class ParentPortalSignUpForm extends React.Component {
    componentDidMount() {
        // eslint-disable-next-line no-undef
        gtag('get', process.env['REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID'], 'client_id', (clientID) => {
            this.setState({googleClientID: clientID})
        })
    }

    setCookieConsent = (value) => {
        if (!value) {
            resetCookieConsentValue()
        }
        else {
            const cookieSecurity = window.location ? window.location.protocol === 'https:' : true
            Cookies.set(defaultCookieConsentName, "true", { expires: 365, sameSite: SAME_SITE_OPTIONS.LAX, secure: cookieSecurity })
        }
    }

    createAccountClicked = async (event) => {
        event.preventDefault()

        const strings = getStrings()

        if (!document.forms[event.target.dataset.form].reportValidity()) return        // EXIT

        const callerFormName = event.target.dataset.form
        const formData = this.getFormDataJSON(callerFormName)

        if (!formData.Email || !formData.Password || !formData.Checkbox) { // It should only get on browsers that don't support .reportValidity() (Mobile Firefox)
            alert(strings.PARENT_PORTAL_SIGN_UP_FORM_ERROR_REQUIRED_FIELDS)
            return  // EXIT
        }
        if (!getCookieConsentValue()) {
            alert(strings.PARENT_PORTAL_SIGN_UP_FORM_ERROR_COOKIE_CONSENT)
            return  // EXIT
        }
        
        this.blockUI(true)

        const uuid = uuidv4()

        const playfabAPIResponse = await this.tryRegisterPlayfabUser(callerFormName, uuid)
        if (!playfabAPIResponse) // Playfab returned an error
            return  // EXIT

        const createUserResponse = await this.tryCreateUser(formData.Email.toLowerCase(), playfabAPIResponse.data.data, uuid)

        if (createUserResponse && createUserResponse.success) {
            ReactGA.event('sign_up', createUserResponse)
            window.fbq('track', 'CompleteRegistration', {
                content_name: 'Parent Portal Sign Up', 
                status: 'Success'
            })
            
            localStorage.setItem('userInfo', JSON.stringify({
                ...createUserResponse,
                playfabToken: uuid,
                queryParams: this.props.location.search.replace('?', ''),
                lang: getLanguage()
            }))

            await this.sendUpdateIntegrationsRequest(uuid)

            if (process.env['REACT_APP_ALLOW_STRIPE_PAYMENT'] === 'true') {
                const purchaseSessionPostPayload = {
                    userID: createUserResponse.id,
                    playfabToken: uuid,
                    lang: getLanguage(),
                    coupon: getCoupon()
                }
                
                const stripeResponse = await axios.post(`${process.env['REACT_APP_STRIPE_API_ENDPOINT']}/subscription/purchaseSession`, purchaseSessionPostPayload)
                window.location.replace(stripeResponse.data.session.url)
            } else {
                // TODO This as it seems to be the wrong behavior. This is asking the user to connect itself to the dashboard.
                this.props.history.push(`/confirmation?playfab_token=${uuid}&${getUrlParameters()}`)
            }
        }
        
        this.blockUI(false)
    }

    tryRegisterPlayfabUser = async (callerFormName, uuid) => {
        let playfabAPIResponse = null

        try {
            playfabAPIResponse = await this.registerPlayfabUser(callerFormName)
        } catch (err) {
            this.blockUI(false)
            this.displayError(err.response.data.error)
            return
        }

        try {
            await this.tryLinkCustomIDs(playfabAPIResponse.data.data, uuid)
        } catch (err) {
            console.error(err) // Don't prevent execution for an error here as this only allows auto-login
        }

        return playfabAPIResponse
    }

    registerPlayfabUser = async (callerFormName) => {
        return await axios.post(
            this.getPlayfabClientAPIEndpoint(PLAYFAB_METHODS.REGISTER_PLAYFAB_USER),
            this.getPlayfabRegisterAccountPayload(callerFormName),
            this.jsonFormHeader()
        )
    }

    tryLinkCustomIDs = async (playfabAPIResponseData, uuid) => {
        // If those fail, the user won't be able to auto-login
        try {
            await this.linkCustomID(playfabAPIResponseData.SessionTicket, PLAYFAB_METHODS.LINK_CUSTOM_ID, { CustomId: uuid })
            await this.linkCustomID(playfabAPIResponseData.SessionTicket, PLAYFAB_METHODS.LINK_ANDROID_DEVICE_ID, { AndroidDeviceId: uuid })
            await this.linkCustomID(playfabAPIResponseData.SessionTicket, PLAYFAB_METHODS.LINK_IOS_DEVICE_ID, { DeviceId: uuid })
        } catch (err) {
            // In an ideal world, we'd have a queue system the wouldn't block execution and would handle retries
            console.error(err)
        }
    }

    linkCustomID = async (sessionTicket, method, payload) => {
        return await axios.post(
            this.getPlayfabClientAPIEndpoint(method),
            payload,
            this.jsonFormHeader(sessionTicket)
        )
    }

    getFormDataJSON = (formName) => Object.fromEntries(new FormData(document.forms[formName]).entries())

    tryCreateUser = async (email, playfabAPIResponseData, uuid) => {
        try {
            const userResponse = await this.sendCreateUserRequest(email, playfabAPIResponseData, uuid)
            
            if (!userResponse.data.success)
                this.displayError(userResponse.data.error)

            return userResponse.data
        } catch (err) {
            this.displayError(err)
        }
    }

    jsonFormHeader(sessionTicket) {
        const headers = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        if (sessionTicket)
            headers['headers']['X-Authorization'] = sessionTicket

        return headers
    }

    wwwFormHeader() {
        return {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
    }

    blockUI = (shouldBlock) => this.context.setBlockUI(shouldBlock)

    sendCreateUserRequest = async (email, playfabAPIResponseData, uuid) => {
        // TODO refactor needed. this calls Uluweb's User API while signin/form.js calls Uluweb's Playfab API for the same purpose.
        return await axios.post(
            // sending p=1 to specify that it comes from the portal. We don't want users to understand what the parameter means so we keep it vague
            `${USER_BASE_ENDPOINTS[process.env['REACT_APP_ENV']]}/user${this.props.location.search ? this.props.location.search + '&p=1' : '?p=1'}`,
            this.getCreateUserPayload(email, playfabAPIResponseData.PlayFabId, uuid),
            this.wwwFormHeader()
        )
    }

    sendUpdateIntegrationsRequest = async (token) => {
        // TODO refactor needed. this is done differently than in signin/form.js for no good reasons
        return await axios.put(
            // sending p=1 to specify that it comes from the portal. We don't want users to understand what the parameter means so we keep it vague
            `${USER_BASE_ENDPOINTS[process.env['REACT_APP_ENV']]}/user/integrations${this.props.location.search ? this.props.location.search + '&p=1' : '?p=1'}`,
            this.getUpdateIntegrationsPayload(token),
            this.wwwFormHeader()
        )
    }

    getPlayfabRegisterAccountPayload = (formName) => {
        const payload = this.getFormDataJSON(formName)
        payload.TitleId = PLAYFAB_TITLE_IDS[process.env['REACT_APP_ENV']]
        payload.RequireBothUsernameAndEmail = false

        return payload
    }

    getCreateUserPayload = (email = '', playfabID = '', uuid = '') => {
        const queryParams = new URLSearchParams()
        queryParams.append('email', email)
        queryParams.append('secret', Buffer.from(process.env['REACT_APP_USER_API_SECRET'], 'utf-8').toString('base64'))
        queryParams.append('sendEmail', true)
        queryParams.append('data[lang]', getLanguage())
        queryParams.append('data[googleClientID]', this.state.googleClientID)
        queryParams.append('activeCampaign[email]', email)
        queryParams.append('activeCampaign[playfab][playfabId]', playfabID)
        queryParams.append('playfab[playfabId]', playfabID)
        queryParams.append('token', uuid)
    
        return queryParams
    }

    getUpdateIntegrationsPayload = (token = '') => {
        const queryParams = new URLSearchParams()

        queryParams.append('token', token)

        return queryParams
    }

    displayError = (errorCode) => {
        const language = getLanguage()
        if (!errorCode || Object.keys(errorCode).length === 0)
            return alert(ERRORS[language].GENERIC_ERROR)
        
        alert(ERRORS[language].hasOwnProperty(errorCode) ? ERRORS[language][errorCode] : ERRORS[language]['GENERIC_ERROR'])
    }

    getPlayfabClientAPIEndpoint = (method = '') => {
        return `https://${PLAYFAB_TITLE_IDS[process.env['REACT_APP_ENV']]}.playfabapi.com/Client/${method}`
    }

    render() {
        const strings = getStrings()

        return (
            <div className='form-box'>
                <p><strong>{strings.PARENT_PORTAL_SIGN_UP_FORM_HAVE_ACCOUNT_P}{' '}
                    <a href={`/signin?${getUrlParameters()}`}>{strings.PARENT_PORTAL_SIGN_UP_FORM_HAVE_ACCOUNT_LINK}</a></strong>
                </p>
                <form id='formCreateAccount'>
                    <input name='Email' type={'email'} placeholder={strings.PARENT_PORTAL_FORM_EMAIL} required className='margin-bottom-2' />
                    <input name='Password' type={'password'} placeholder={strings.PARENT_PORTAL_FORM_PASSWORD} minLength={8} required className='margin-bottom-2' />
                    <label className='flex-row flex-nowrap small-text margin-bottom-2'>
                        <input name="Checkbox" type={'checkbox'} required className='margin-right-2' />
                        <span dangerouslySetInnerHTML={{__html: strings.PARENT_PORTAL_FORM_AGREEMENT}}></span>
                    </label>
                    <label className='flex-row flex-nowrap small-text margin-bottom-2'>
                        <input name="Cookies" type='checkbox' required className='margin-right-2' onChange={(event) => this.setCookieConsent(event.target.checked)} />
                        <span>{strings.PARENT_PORTAL_SIGN_UP_FORM_COOKIE_CONSENT_P}</span>
                    </label>
                    <button 
                        data-form='formCreateAccount'
                        onClick={this.createAccountClicked.bind(this)}
                        className="form-button"
                        type='submit'
                    >
                        {strings.PARENT_PORTAL_FORM_BUTTON}
                    </button>
                </form>
            </div>
        )
    }
}

ParentPortalSignUpForm.contextType = AppContext

export default withRouter(ParentPortalSignUpForm)