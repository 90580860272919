import React from 'react'

const UIBlocker = (props) => {
    const shown = props.shown || props.display === 'block';
    return (
        <div className={'blocker-backdrop' + (shown ? '' : ' hidden' )}>
            <img src='/images/LlamaBongo.gif' alt='llama playing the bongos' />
            <h1 className='text-white'>Loading...</h1>
        </div>
    )
}

export default UIBlocker