const analyticsSetUp = () => {
    const gtag = process.env['REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID']
    const optimize = process.env['REACT_APP_GOOGLE_OPTIMIZE_CONTAINER_ID']

    const googleAnalyticsScript = document.createElement('script')
    googleAnalyticsScript.type = 'text/javascript'
    googleAnalyticsScript.src = `https://www.googletagmanager.com/gtag/js?id=${gtag}`
    googleAnalyticsScript.async = true
    document.head.appendChild(googleAnalyticsScript)
    
    const googleAnalyticsConfig = document.createElement('script')
    googleAnalyticsConfig.type = 'text/javascript'
    googleAnalyticsConfig.text = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${gtag}');`
    document.head.appendChild(googleAnalyticsConfig)
}

export default analyticsSetUp