import React, { useEffect } from 'react'
import { withRouter } from 'react-router'

import ParentPortalHeader from '../header'
import ParentPortalManageAccount from './manageaccount'
import getUrlParameters from 'getUrlParameters'
import {logAnalytics, EVENT_TYPES} from 'logAnalytics'

const ParentPortalDashboard = (props) => {
    useEffect(() => {
        document.title = 'Math Makers Parent Portal Dashboard'
        logAnalytics(EVENT_TYPES.PAGE_VIEW, {page: document.title})
    }, [])

    const isSignedIn = localStorage.getItem('session')

    if (!isSignedIn)
        props.history.push(`/signin?${getUrlParameters()}`)

    return (
        <>
            <ParentPortalHeader />
            <ParentPortalManageAccount />
        </>
    )
}

export default withRouter(ParentPortalDashboard)