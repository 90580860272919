import React from 'react'
import axios from 'axios'

import TeacherSignUpExplanation from './TeacherSignUpExplanation'
import ParentPortalHeader from '../../parentportal/header'
import getStrings from 'getStrings'
import KidSafe from 'core/kidsafe'

class TeacherSignUp extends React.Component {
    constructor(props) {
        super(props)
        
        this.state = {
            mounted: false,
        }
    }
    
    componentDidMount() {
        if (process.env.hasOwnProperty('REACT_APP_SCHOOLZONE_TEACHER_REDIRECT') && process.env['REACT_APP_SCHOOLZONE_TEACHER_REDIRECT'] !== 'None')
            window.location.href = process.env['REACT_APP_SCHOOLZONE_TEACHER_REDIRECT']
        
        document.title = 'Math Makers Teacher SignUp'

        axios.get('https://geolocation-db.com/json/').then(result => {
            const logData = {
                type: 'PageView',
                timestamp: Date.now(),
                data: JSON.stringify({
                    page: document.title,
                    ...result.data
                })
            }
            axios.post(`${process.env['REACT_APP_ANALYTICS_API']}/log`, logData).then(() => {})
        })
        .catch((err) => console.error('Failed to GET geolocation-db with error: ', err))

        this.setState({
            mounted: true
        })
    }

    render() {
        if (!this.state.mounted)
            return "";

        const strings = getStrings()

        return (
            <>
                <div className='hero-bg'>
                    <ParentPortalHeader />
                    <div className='container text-center schoolzone--hero'>
                        <p className='lede'>{strings.TEACHER_LOUNGE_HEADER}</p>
                        <h1 className='text-blue'>{strings.TEACHER_LOUNGE_EXPLANATION_PARAGRAPH_1}</h1>
                        <p>{strings.TEACHER_LOUNGE_SUBTITLE}</p>
                        <KidSafe />
                    </div>
                </div>
                <TeacherSignUpExplanation />
            </>
        )
    }
}

export default TeacherSignUp