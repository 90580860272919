import getStrings from './getStrings'

export default function getErrorMessage(prefix, errorCode, strings) {
    if (!strings)
        strings = getStrings()

    const standardErrorCode = errorCode.includes('_') ? errorCode.toUpperCase() : errorCode.split(/(?=[A-Z])/).join('_').toUpperCase() // Split string from uppercase characters
    const fullErrorCode = `${prefix}${standardErrorCode}`

    return Object.keys(strings).includes(fullErrorCode) ? strings[fullErrorCode] : strings.PARENT_PORTAL_MANAGE_ACCOUNT_ERROR_GENERIC_ERROR
}