import React from 'react'

const LogosContainer = (props) => {
    return (
        <div className="awards-wrapper">
            <div className="flex-row awards-container margin-bottom-2">
                <img src="/images/awards/G4C22_Nom_Learning_laurels.png" alt="" />
                <img src="/images/awards/2022_gee_laurels_winner.png" alt="" />
                <img src="/images/awards/YMA_Laurel_Logo-nbg.png" alt="" />
                <img src="/images/awards/2022-Gold.png" alt="" />
                <img src="/images/awards/Certified_Badge.png" alt="" />
            </div>
        </div>
    )
}

export default LogosContainer