import React from 'react'
import { withRouter } from 'react-router'

import LanguageBar from 'core/languagebar'
import getUrlParameters from 'getUrlParameters'
import getStrings from 'getStrings'

const ParentPortalHeader = (props) => {
    const strings = getStrings()
    const isSignedIn = localStorage.getItem('session')

    return (
        <header>
            <div className='container flex-row flex-nowrap padding-top-2'>
                <div className='header-logo--wrap margin-right-2'>
                    <a href={`${isSignedIn ? '/dashboard' : ''}/?${getUrlParameters()}`}>
                        <img className='header-logo' src={`${process.env.PUBLIC_URL}/images/ululab-logo.svg`} alt='Ululab logo' height='52' width='131' />
                    </a>
                </div>
                <LanguageBar />
                <div className='margin-left-auto'>
                    <a href={`/signin/?${getUrlParameters()}`} className='header-login-link undecorated'>{strings.PARENT_PORTAL_HEADER_LOGIN_LINK}</a>
                    <a href={`/signup/?${getUrlParameters()}`} className='header-trial-cta undecorated'>{strings.PARENT_PORTAL_HEADER_START_TRIAL}</a>
                </div>
            </div>
        </header>
    )
}

export default withRouter(ParentPortalHeader)
