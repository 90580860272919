import CollapsiblePanel, { CollapsiblePanelContent, CollapsiblePanelHeader } from "core/collapsible-panel"
import getStrings from "getStrings"

import styles from './classroom.module.scss'

// TODO: Future feature: Filter by date range
// TODO: Future feature: Dropdown to view progress in specific concept

const PACKAGES_TO_EXCLUDE = ['demo', 'custom_creative_mode', 'custom_creative_mode_walk', 'hub', 'parent_prompt_gameplay']

export default function GridConcepts({ mappedData, packageConcepts, refreshData }) {
    const strings = getStrings()

    if (!mappedData)
        return (
            <img src='/images/LlamaBongo.gif' alt='llama playing the bongos' />
        )

    const supportedPackagesKeys = Object.keys(packageConcepts).filter(pack => !PACKAGES_TO_EXCLUDE.includes(pack))

    const cleanForDisplay = (concept) => {
      return concept
        .replaceAll('_', ' ')
        .replaceAll('sf1', '')
        .replaceAll('sf2', '')
        .trim()
    }

    const getDisplayString = (packageKey, concept) => {
        const stringName = 'PACKAGE_' + packageKey.toUpperCase() + '_CONCEPT_' + concept.toUpperCase()
        if (strings.hasOwnProperty(stringName)) {
            return strings[stringName]
        }
        return cleanForDisplay(concept)
    }

    if (supportedPackagesKeys.length === 0)
        return (
            <p>{strings.TEACHER_DASHBOARD_NO_STUDENT_PROGRESS}</p>
        )

    
    return (
        <>
          { supportedPackagesKeys.map((packageKey) => (
              <CollapsiblePanel key={packageKey} className="margin-bottom-2">
                  <CollapsiblePanelHeader className={styles.packageHeader}>
                      <p className="margin-bottom-none text-center">{strings.hasOwnProperty('PACKAGE_' + packageKey.toUpperCase() + '_TITLE') ? strings['PACKAGE_' + packageKey.toUpperCase() + '_TITLE'] : packageKey}</p>
                  </CollapsiblePanelHeader>

                  <CollapsiblePanelContent>
                      <table className={styles.table}>
                          <thead className={styles.thead}>
                              <tr>
                                  <th className={styles.th}>{strings.TEACHER_DASHBOARD_STUDENTS}</th>
                                  {packageConcepts[packageKey].map((concept) => (
                                      <th key={concept} className={styles.th}>{getDisplayString(packageKey, concept)}</th>
                                  ))}
                              </tr>
                          </thead>
                          <tbody>
                              {Object.keys(mappedData[packageKey]).map((studentCode) => (
                                  <tr key={packageKey + '-' + studentCode}>
                                      <td className={styles.td}>#{studentCode}</td>
                                      {packageConcepts[packageKey].map((concept) => (
                                          <td key={packageKey + '-' + studentCode + '-' + concept} className={styles.td}>
                                              {mappedData[packageKey][studentCode][concept]?.completed || 0}
                                              {' / '}
                                              {mappedData[packageKey][studentCode][concept]?.total || 0}
                                          </td>
                                      ))}
                                  </tr>
                              ))}
                          </tbody>
                      </table>
                      <div className="flex-row justify-center margin-top-2">
                          <button className="button bordered blue" onClick={() => refreshData ? refreshData() : {}()}>Refresh</button>
                      </div>
                  </CollapsiblePanelContent>
              </CollapsiblePanel>
          )) }
        </>
    )
}