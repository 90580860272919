import getLanguage from 'getLanguage'
import getCoupon from 'getCoupon'

export default function getUrlParameters() {
    let query = `lang=${getLanguage()}`

    const coupon = getCoupon()
    if (coupon)
        query = `${query}&coupon=${coupon}`

    return query
}