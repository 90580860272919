import React from 'react'

import TeacherSignUpForm from './TeacherSignUpForm'
import getStrings from 'getStrings'
import LogosContainer from 'LogosContainer'

const TeacherSignUpExplanation = (props) => {
    const strings = getStrings()
    
    return (
        <div className='schoolzone-signup'>
            <div className='container flex-row'>
                <TeacherSignUpForm />
                <div>
                    <h1 className='text-orange'>{strings.TEACHER_LOUNGE_EXPLANATION_HEADER}</h1>
                    <p>{strings.TEACHER_LOUNGE_EXPLANATION_PARAGRAPH_2}</p>
                    <p>{strings.TEACHER_LOUNGE_EXPLANATION_PARAGRAPH_3}</p>
                    <p>{strings.TEACHER_LOUNGE_EXPLANATION_PARAGRAPH_4}</p>
                    <h1 className='text-blue'>{strings.TEACHER_LOUNGE_BENEFITS_HEADER}</h1>
                    <ul className='margin-left-1'>
                        <li><img src="/images/orange-check.png" className='icon' alt="" />{strings.TEACHER_LOUNGE_BENEFITS_1}</li>
                        <li><img src="/images/orange-check.png" className='icon' alt="" />{strings.TEACHER_LOUNGE_BENEFITS_2}</li>
                        <li><img src="/images/orange-check.png" className='icon' alt="" />{strings.TEACHER_LOUNGE_BENEFITS_3}</li>
                        <li><img src="/images/orange-check.png" className='icon' alt="" />{strings.TEACHER_LOUNGE_BENEFITS_4}</li>
                    </ul>
                    <LogosContainer />
                </div>
            </div>
        </div>
    )
}

export default TeacherSignUpExplanation