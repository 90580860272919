import React, { useContext } from 'react'
import { withRouter } from 'react-router'
import axios from 'axios'
import getStrings from 'getStrings'
import getLanguage from 'getLanguage'
import getUrlParameters from 'getUrlParameters'
import styles from './deleteaccount.module.scss'
import AppContext from 'core/appContext'

const ParentPortalDashboardDeleteAccount = (props) => {
    const context = useContext(AppContext)
    const blockUI = (shouldBlock) => context.setBlockUI(shouldBlock)

    const handleDeleteAccount = async () => {
        const strings = getStrings()
        if (!window.confirm(strings.PARENT_PORTAL_DELETE_ACCOUNT_CONFIRMATION_DIALOGUE)) {
            props.deleteAccountDisplay(false)
            return
        }

        blockUI(true)
        
        const token = JSON.parse(localStorage.getItem('session')).token
        const deleteUserResponse = await axios.delete(`${process.env['REACT_APP_USER_API']}/?lang=${getLanguage()}&token=${token}`)

        if (deleteUserResponse.data.success) {
            localStorage.removeItem('session')
            props.history.push(`/?${getUrlParameters()}`)
        }
        else
            alert(`${strings.PARENT_PORTAL_DELETE_ACCOUNT_FAILED_ALERT} ${deleteUserResponse.data.error}`)

        blockUI(false)
    }

    const handleBackdropClick = (event) => {
      if (event.target === event.currentTarget) {
        event.preventDefault()
        props.deleteAccountDisplay(false)
      }
    }

    const strings = getStrings()

    return (
        <div className={styles.backdrop + (!!props.shown ? ' ' + styles.shown : '')} onClick={handleBackdropClick}>
            <div className={styles.popup}>
                <button
                    onClick={() => props.deleteAccountDisplay(false)} 
                    className={styles.closeButton}>
                    X
                </button>
                <h1 className='text-center text-blue'>{strings.PARENT_PORTAL_DELETE_ACCOUNT_WARNING_H1}</h1>
                <h3 className='text-center text-blue'><strong>{strings.PARENT_PORTAL_DELETE_ACCOUNT_WARNING_H2}</strong></h3>
                <p>{strings.PARENT_PORTAL_DELETE_ACCOUNT_WARNING_P}</p>
                <ul>
                    <li>{strings.PARENT_PORTAL_DELETE_ACCOUNT_WARNING_LI_1}</li>
                    <li>{strings.PARENT_PORTAL_DELETE_ACCOUNT_WARNING_LI_2}</li>
                    <li>{strings.PARENT_PORTAL_DELETE_ACCOUNT_WARNING_LI_3}</li>
                    <li>{strings.PARENT_PORTAL_DELETE_ACCOUNT_WARNING_LI_4}</li>
                </ul>
                <h3 className={'text-center text-blue ' + styles.topSpacing}>{strings.PARENT_PORTAL_DELETE_ACCOUNT_WARNING_H3}</h3>
                <p>{strings.PARENT_PORTAL_DELETE_ACCOUNT_WARNING_P_2}</p>
                <div className='flex-row justify-center gap-2'>
                    <a className='button bordered blue' href='https://support.apple.com/en-ca/HT202039' target='_blank' rel='noreferrer'>{strings.PARENT_PORTAL_DELETE_ACCOUNT_IOS}</a>
                    <a className='button bordered blue' href='https://support.google.com/googleplay/answer/7018481?hl=en&co=GENIE.Platform%3DAndroid#zippy=%2Ccancel-a-subscription-on-the-google-play-app' target='_blank' rel='noreferrer'>{strings.PARENT_PORTAL_DELETE_ACCOUNT_ANDROID}</a>
                </div>
                <button className={'button contained warn margin-bottom-2 margin-left-auto margin-right-auto ' + styles.topSpacing} onClick={() => handleDeleteAccount()}>
                    {strings.PARENT_PORTAL_DELETE_ACCOUNT_WARNING_BUTTON}
                </button>
                <p className='text-center'>
                    <em>{strings.PARENT_PORTAL_DELETE_ACCOUNT_WARNING_CONTACT} <a className={styles.link} href='mailto:support@ululab.com'>support@ululab.com</a>.</em>
                </p>
                <img src={`${process.env.PUBLIC_URL}/images/parentportal/sad.png`} alt='sad llama' className={styles.sadLlama} /> 
            </div>
        </div>
    )
}

export default withRouter(ParentPortalDashboardDeleteAccount)
