import React from 'react'
import axios from 'axios'
import getStrings from 'getStrings'
import getLanguage from 'getLanguage'
import AppContext from 'core/appContext'

const FORMS = {
    CREATE_TEACHER: 'createTeacher',
    UPDATE_CLASSROOM: 'updateClassroom'
}

const FORM_STATES = {
    INITIAL: 'INITIAL',
    SUCCESS: 'SUCCESS'
}

const HTTP_METHODS = {
    POST: 'post',
    PUT: 'put'
}


class TeacherSignUpForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            form: FORMS.CREATE_TEACHER,
            formState: FORM_STATES.INITIAL,
            email: '',
            name: ''
        }
    }

    getBaseEndpoint = () => {
        switch (process.env['REACT_APP_ENV']) {
            case "prod": return 'https://hntwyyngq8.execute-api.us-east-1.amazonaws.com/prod'
            case "dev": return 'https://twq3jitb16.execute-api.us-east-1.amazonaws.com/dev'
            case 'danny': return 'https://d3patqtvf3.execute-api.us-east-1.amazonaws.com/danny'
            case 'gabriel': return 'https://i8316be193.execute-api.us-east-1.amazonaws.com/gabriel'
            default: return 'https://d3patqtvf3.execute-api.us-east-1.amazonaws.com/danny'
        }
    }

    getTeacherSignUpEndpoint = () => {
        return `${this.getBaseEndpoint()}/teachers?lang=${getLanguage()}`
    }
    
    getUpdateClassroomEndpoint = () => {
        return `${this.getBaseEndpoint()}/classrooms?lang=${getLanguage()}`
    }

    getFormData(form) {
        const formData = new FormData(document.forms[form])

        const params = new URLSearchParams()

        formData.forEach((value, key) => {
            if (key === 'reinitialize')
                // Checkboxes are reported as 'on' but the backend is expecting a bool value.
                params.append(key, value === 'on')
            else
                params.append(key, value)
        })

        return params
    }

    getCustomHeader() {
        return {
            Headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
    }

    blockUI = (shouldBlock) => {
        this.context.setBlockUI(shouldBlock)
    }

    switchForm = (event, form) => {
        event.preventDefault()
        this.setState({
            form: form
        })
    }
    
    displayError = (errorCode) => {
        console.log(errorCode)
        if (!errorCode || Object.keys(errorCode).length === 0)
            return alert(getStrings()['GENERIC_ERROR'])

        alert(getStrings()[`TEACHER_SIGNUP_${errorCode}`])
    }

    createTeacherClicked = (event) => {
        event.preventDefault()

        this.sendRequest(
            this.getTeacherSignUpEndpoint(),
            HTTP_METHODS.POST,
            event.target.dataset.form
        )
    }

    updateClassroomClicked = (event) => {
        event.preventDefault()

        this.sendRequest(
            this.getUpdateClassroomEndpoint(),
            HTTP_METHODS.PUT,
            event.target.dataset.form
        )
    }

    sendRequest = (url, method, formName, successHandler) => {
        if (!document.forms[formName].reportValidity())
            return

        this.blockUI(true)

        axios[method](
            url, 
            this.getFormData(formName), 
            this.getCustomHeader())
        .then((response) => {
            if (successHandler)
                return successHandler(response)
            
            this.handleServerResponse(response)
        }).catch((err) => {
            console.error(err)
        })
    }

    handleServerResponse = (response) => {
        this.blockUI(false)

        if (!response.data.success)
            this.displayError(response.data.error)
        else
            this.setState({
                formState: FORM_STATES.SUCCESS
            })
    }

    render() {
        return (
            <>
                {this.state.form === FORMS.CREATE_TEACHER   && this.renderFormCreateTeacher()}
                {this.state.form === FORMS.UPDATE_CLASSROOM && this.renderFormUpdateClassroom()}
            </>
        )
    }

    renderSuccess() {
        return (
            <div className='form-container form-box'>
                <p>{getStrings().TEACHER_LOUNGE_FORM_SUCCESS}</p>
                <img src={'/images/HappyLlamaMail.png'} alt="Happy Llama" />
            </div>
        )
    }

    renderFormCreateTeacher() {
        if (this.state.formState === FORM_STATES.SUCCESS)
            return this.renderSuccess()

        const strings = getStrings()

        const allowSubmit = this.state.email && this.state.name

        return (
            <form id="formTeacherSignUp" className='form-container form-box'>
                <p>{strings.TEACHER_LOUNGE_FORM_HEADER}{' '}
                    <button className='link' onClick={(e) => this.switchForm(e, FORMS.UPDATE_CLASSROOM)}>{strings.TEACHER_LOUNGE_FORM_UPDATE_BUTTON}</button>
                </p>
                <p className='tiny-text margin-bottom-2'>* {strings.TEACHER_LOUNGE_FORM_REQUIRED_FIELDS}</p>
                <input 
                    name="email" 
                    type="email" 
                    className='margin-bottom-2'
                    placeholder={strings.TEACHER_LOUNGE_FORM_EMAIL + '*'}
                    value={this.state.email}
                    onChange={e => this.setState({email: e.target.value})}
                    required 
                />
                <input 
                    name="name" 
                    type="text" 
                    className='margin-bottom-2'
                    placeholder={strings.TEACHER_LOUNGE_FORM_NAME + '*'}
                    value={this.state.name}
                    onChange={e => this.setState({name: e.target.value})}
                    required 
                />
                <input
                    name="numStudents"
                    type="number"
                    className='margin-bottom-2'
                    placeholder={strings.TEACHER_LOUNGE_FORM_NUM_STUDENTS + '*'}
                    min="1"
                    max="35"
                    //defaultValue="23"
                    required
                />
                <input
                    name="schoolName"
                    type="text"
                    className='margin-bottom-2'
                    placeholder={strings.TEACHER_LOUNGE_FORM_SCHOOL_NAME}
                />
                <select
                    name="ageGroup"
                    className='margin-bottom-2'
                    defaultValue={''}
                >
                    <option value="" disabled hidden>{strings.TEACHER_LOUNGE_FORM_AGE_GROUP}</option>
                    <option value="4-5">4-5</option>
                    <option value="5-6">5-6</option>
                    <option value="6-7">6-7</option>
                    <option value="7-8">7-8</option>
                    <option value="8-9">8-9</option>
                    <option value="9-10">9-10</option>
                    <option value="11+">11+</option>
                </select>
                <p className='margin-bottom-2 small-text'>{strings.TEACHER_LOUNGE_FORM_EXPLANATION_1}</p>
                <button 
                    disabled={!allowSubmit}
                    data-form='formTeacherSignUp'
                    onClick={this.createTeacherClicked.bind(this)}
                    className="form-button"
                >
                    {strings.TEACHER_LOUNGE_FORM_CREATE_BUTTON}
                </button>
            </form>
        )
    }

    renderFormUpdateClassroom() {
        if (this.state.formState === FORM_STATES.SUCCESS)
            return this.renderSuccess()
            
        const strings = getStrings()

        return (
            <form id='formClassroomUpdate' className='form-container form-box'>
                <p>{strings.TEACHER_LOUNGE_UPDATE_FORM_HEADER}{' '}
                    <button className='link' onClick={(e) => this.switchForm(e, FORMS.CREATE_TEACHER)}>{strings.TEACHER_LOUNGE_UPDATE_FORM_BACK}</button>
                </p>
                <p className='tiny-text margin-bottom-2'>* {strings.TEACHER_LOUNGE_FORM_REQUIRED_FIELDS}</p>
                <input
                    name="email"
                    type="email"
                    placeholder={strings.TEACHER_LOUNGE_UPDATE_FORM_EMAIL + '*'}
                    className="margin-bottom-2"
                    required
                />
                <input
                    name='numStudents'
                    type="number"
                    min="1"
                    max="35"
                    //defaultValue={35}
                    placeholder={strings.TEACHER_LOUNGE_UPDATE_FORM_NUMBER_STUDENTS + '*'}
                    className='margin-bottom-2'
                    required
                />
                <input
                    name="reinitialize"
                    type="checkbox"
                    className='margin-bottom-2'
                />
                <label>
                    {strings.TEACHER_LOUNGE_UPDATE_FORM_REINITIALIZATION}
                </label>
                <button 
                    data-form='formClassroomUpdate'
                    onClick={this.updateClassroomClicked.bind(this)}
                    className="form-button margin-bottom-2"
                >
                    {strings.TEACHER_LOUNGE_UPDATE_FORM_BUTTON}
                </button>
            </form>
        )
    }
}

TeacherSignUpForm.contextType = AppContext

export default TeacherSignUpForm