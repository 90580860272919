import {useCallback, useContext, useEffect, useState} from "react"
import axios from 'axios'
import {withRouter} from "react-router-dom"

import GridConcepts from "./grid-concepts"
import ParentPortalHeader from 'parentportal/header'
import getStrings from "getStrings"
import AppContext from "core/appContext"


const TeacherClassroom = (props) => {
    const context = useContext(AppContext)
    const {match} = props
    const id = match.params?.id;

    const [classroomData, setClassroomData] = useState(null)
    const [isDataLoaded, setIsDataLoaded] = useState(false)

    const strings = getStrings()

    const loadData = useCallback(() => {
        setIsDataLoaded(false)
        axios.get(`${process.env['REACT_APP_SCHOOLZONE_API']}/classrooms/${id}`)
            .then((response) => {
                if (response.data?.success) {
                    setClassroomData(response.data)
                }
            })
            .finally(() => setIsDataLoaded(true))
    }, [id])

    useEffect(() => {
        document.title = 'Math Makers Teacher Dashboard'
    }, [])
    useEffect(() => {
        loadData()
    }, [id, loadData])
    useEffect(() => {
        context.setBlockUI(!isDataLoaded)
    }, [context, isDataLoaded])

    const getPackageConcepts = () => {
        if (!classroomData)
            return []

        const packageConcepts = {}
        classroomData.students.forEach(student => {
            if (student.hasOwnProperty('levelStatus')) {
                const levelStatus = JSON.parse(student.levelStatus)
                Object.keys(levelStatus).forEach(packageKey => {
                    if (!packageConcepts.hasOwnProperty(packageKey))
                        packageConcepts[packageKey] = []

                    packageConcepts[packageKey] = packageConcepts[packageKey].concat(Object.keys(levelStatus[packageKey]).filter(concept => !packageConcepts[packageKey].includes(concept)));
                })
            }
        })

        return packageConcepts
    }

    function getUniqueObjects(array, property) {
        const uniqueObjectMap = array.reduce((accumulator, currentObject) => {
            if (!accumulator[currentObject[property]]) {
                accumulator[currentObject[property]] = currentObject;
            }
            return accumulator;
        }, {});

        return Object.values(uniqueObjectMap);
    }

    const mapData = () => {
        if (!classroomData)
            return {}

        const levelData = {}
        classroomData.students.forEach((student) => {
            if (!student.hasOwnProperty('levelStatus'))
                return

            const levelStatus = JSON.parse(student.levelStatus)
            Object.keys(levelStatus).forEach((packageKey) => {
                if (!levelData.hasOwnProperty(packageKey))
                    levelData[packageKey] = {}
                levelData[packageKey][student.studentCode] = {}
                Object.keys(levelStatus[packageKey]).forEach((concept) => {
                    levelData[packageKey][student.studentCode][concept] = {
                        total: levelStatus[packageKey][concept].split('/')[1] || 0,
                        completed: getUniqueObjects(
                            student.levelsPlayed.filter((level) => level.concept === concept && level.completed),
                            'level'
                        ).length || 0,
                    }
                })
            })
        })

        return levelData
    }

    return (
        <>
            <div className='hero-bg schoolzone'>
                <ParentPortalHeader/>
                <div className="container">
                    {classroomData && classroomData.code &&
                        <h1 className="padding-top-2 padding-bottom-2 text-center text-blue">
                            {`${strings['TEACHER_DASHBOARD_CLASSROOM_CODE']}${classroomData.name}: ${classroomData.code}`}
                        </h1>
                    }
                </div>
            </div>
            <div className="container form-outer">
                {classroomData && classroomData.code && (
                    <>
                        <h1 className="text-center text-blue">{strings['TEACHER_DASHBOARD_PROGRESS_LABEL']}</h1>
                        <GridConcepts
                            classroomData={classroomData}
                            packageConcepts={getPackageConcepts()}
                            isDataLoaded={isDataLoaded}
                            mappedData={mapData()}
                            refreshData={loadData}
                        />
                    </>
                )}
            </div>
        </>
    )
}

export default withRouter(TeacherClassroom)
