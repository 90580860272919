import React, { useContext, useState } from "react"

import styles from './collapsible-panel.module.scss'

const PanelContext = React.createContext();

export const CollapsiblePanelHeader = ({ children, className, ...miscProps }) => {
  const panelContext = useContext(PanelContext)

  return (
    <div className={styles.header + (className ? ' ' + className : '')} onClick={() => panelContext.setIsOpen(!panelContext.isOpen)} {...miscProps}>
      <div className={styles['header-inner']}>
        {children}
      </div>
      <span className={styles.indicator + ' ' + (panelContext.isOpen ? styles.open : styles.closed)}></span>
    </div>
  )
}

export const CollapsiblePanelContent = ({ children }) => {
  const panelContext = useContext(PanelContext)

  if (!panelContext.isOpen)
    return null
  
  return (
    <div className={styles.content}>
      {children}
    </div>
  )
}

export const CollapsiblePanel = ({ title, children, ...miscProps }) => {
  const [isOpen, setIsOpen] = useState(false)

  const headerNode = React.Children.toArray(children).find((child) => (child.type === CollapsiblePanelHeader))
  const contentNode = React.Children.toArray(children).find((child) => (child.type === CollapsiblePanelContent))
  const content = React.Children.toArray(children).filter((child) => ( child.type !== CollapsiblePanelHeader && child.type !== CollapsiblePanelContent ))

  return (
    <PanelContext.Provider value={{ isOpen, setIsOpen }}>
      <div {...miscProps}>
        {headerNode || (
          <CollapsiblePanelHeader>{title}</CollapsiblePanelHeader>
        )}
        {contentNode || (
          <CollapsiblePanelContent>{content}</CollapsiblePanelContent>
        )}
      </div>
    </PanelContext.Provider>
  )
}
export default CollapsiblePanel
