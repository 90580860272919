/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "admin",
            "endpoint": "https://bjd9ywxpt9.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "analytics",
            "endpoint": "https://e2435h7zkb.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "appleappstore",
            "endpoint": "https://qdxwep5yy1.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "cloudbuild",
            "endpoint": "https://dhljkyq184.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "googleplay",
            "endpoint": "https://tekk6hu767.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "playfab",
            "endpoint": "https://115rqdxz45.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "revenuecat",
            "endpoint": "https://vq2dzdiw93.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "schoolzone",
            "endpoint": "https://hntwyyngq8.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "stripe",
            "endpoint": "https://fmx7myujch.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "user",
            "endpoint": "https://oss5z18d5j.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://vmqyv2iwljfezmrezyp2ckopze.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:871975f7-f8e9-439a-a97c-5adf42ff8d55",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_lk2esPDt5",
    "aws_user_pools_web_client_id": "6rt5so28engvbk18smt1cpvu22",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "mathmakerslevels112656-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
