import React, { useContext } from "react"
import { withRouter } from "react-router-dom"
import qs from 'qs'
import getLanguage from "getLanguage"

import styles from './languagebar.module.scss'
import AppContext from "core/appContext"


const flagEn = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAMAAABBPP0LAAAAh1BMVEXpTFTwNj7rHiTz8/Pv7u7XAgLuKTP4o6f0l5n9/f327e36+vv39/fnaGvtbnPIAAD1jpPwgIPoe4H19fXhPUDwdXfy0NHtY2XoXmLowcLsvr/fREu+AADwiIzy8vLeLzT1mp/qWFjcJSrgUVeyAADs4+PYGx7kBgjprazr6+umAADPz8/ExMQoJmQzAAAAiElEQVR4ASWJRULDUBCGv5l4gm9wVtj9jwNt37LuEveM/Cq0K/TTs7hDMBT9u4EkqNunq2u1AZtrpJ35/Wtpq9M6jev4XGevUQqiH/uyfLDj4JjGH6UtyoyA9Wpmbp+wAf3ybZEg/v1V84WVoFyngbd7KcvWGxtXwVoBxvDPyzlFHAjfAAEqoAEThSiV/9sl/gAAAABJRU5ErkJggg==';
const flagFr = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAALCAIAAAAWQvFQAAAAZElEQVR4AWNgUJkKQfyGc+DsV2+//f//H0hilWWAC81fex1IounBlEXoMQtes3bnXSCJqQdTFuE2JcfFmG7DKstw/8knTPQfBrDKMvwnHZBjD1n+gQsBQxNIoulBkyVfDznpAADm6jhzSAHoQgAAAABJRU5ErkJggg==';

const LanguageBar = (props) => {
    const context = useContext(AppContext)
    const currentLang = getLanguage()
    const handleChange = (event, value) => {
        event.preventDefault();
        event.stopPropagation();
        if (value !== currentLang) {
            context.setLang(value)
            const { lang, ...query } = qs.parse(props.location.search, { ignoreQueryPrefix: true })
            query['lang'] = value;
            window.localStorage?.setItem('lang', value)
            props.history.replace(props.location.pathname + '?' + qs.stringify(query))
        }
    }
    const langOptions = {
        'en': (
            <a className={styles.langOption} href="?lang=en" onClick={(event) => handleChange(event, 'en')}>
                <img className={styles.flagImg} src={flagEn} alt="" />
                English
            </a>
        ),
        'fr': (
            <a className={styles.langOption} href="?lang=fr" onClick={(event) => handleChange(event, 'fr')}>
                <img className={styles.flagImg} src={flagFr} alt="" />
                Français
            </a>
        ),
    }

    return (
        <div className={styles.langSelect}>
            {langOptions[currentLang]}
            <ul>
                {Object.keys(langOptions).map((lang) => {
                    if (lang !== currentLang) {
                        return (<li key={lang}>{langOptions[lang]}</li>)
                    }
                    return ''
                })}
            </ul>
        </div>
    )
}

export default withRouter(LanguageBar)
