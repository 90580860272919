import React, { useState } from 'react';

import './App.css';
import {Switch, Route, Redirect} from 'react-router-dom';
import TeacherSignUp from './schoolzone/Teacher/TeacherSignUp';
import ParentPortalIntro from './parentportal/intro';
import ParentPortalSignUp from './parentportal/signup';
import ParentPortalSignIn from './parentportal/signin';
import ParentPortalDashboard from './parentportal/dashboard';
import ParentPortalConfirmation from './parentportal/confirmation';
import DevToolsUGC from './devtools/ugc';
import TeacherClassroom from './schoolzone/Teacher/Classroom';
import UluFooter from './core/footer';
import AppContext from 'core/appContext';
import getLanguage from 'getLanguage';
import UIBlocker from 'core/UIBlocker';

function App() {
  const [lang, setLang] = useState(getLanguage())
  const [blockUI, setBlockUI] = useState(false)
  const context = {
    ...AppContext.value,
    lang,
    setLang,
    blockUI,
    setBlockUI,
  };

  // We do not want to use the portal.ululab.com subdomain anymore.
  // We are thus redirecting requests to the play.ululab.com subdomain
  // Maintaining both create issues as the localStorage content (token, userInfo, ...)
  // is specific for the subdomain and cannot be shared between both subdomains.
  // A different solution would be to use domain-wide cookies instead of localStorage (limit of 4Kb for all cookies).
  if (window.location.href.includes("portal.ululab.com"))
    window.location.href = window.location.href.replace("portal.ululab.com","play.ululab.com")

  return (
    <AppContext.Provider value={context}>
      <UIBlocker shown={blockUI} />
      <Switch>
        <Route exact path="/" component={ParentPortalIntro}/>
        <Route exact path="/devtools/ugc" component={DevToolsUGC} />
        <Route exact path="/signup" component={ParentPortalSignUp}/>
        <Route exact path="/signin" component={ParentPortalSignIn}/>
        <Route exact path="/dashboard" component={ParentPortalDashboard}/>
        <Route exact path="/confirmation" component={ParentPortalConfirmation}/>
        <Route exact path="/schoolzone/teacher/signup" component={TeacherSignUp} />
        <Route exact path="/schoolzone/teacher/classroom/:id" component={TeacherClassroom} />
        <Route path="/schoolzone/teacher">
            <Redirect to="/schoolzone/teacher/signup" />
        </Route>
        <Route path="*">
            <Redirect to="/" />
        </Route>
      </Switch>
      <UluFooter />
    </AppContext.Provider>
  )
}

export default App;
