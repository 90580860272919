import qs from 'qs'

const DEFAULT_LANGUAGE = 'en'

const SUPPORTED_LANGUAGES = ['en', 'fr']

export default function getLanguage() {
    const { lang } = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    const langSetting = window.localStorage?.getItem('lang') || DEFAULT_LANGUAGE
    const currentLang = lang || langSetting

    return SUPPORTED_LANGUAGES.includes(currentLang) ? currentLang : DEFAULT_LANGUAGE
}